<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col cols="12" sm="6" md="8">
        <div>
          <h1>ADD PDF TO BID</h1>
        </div>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EnterBid',
                params: { id: $route.params.id },
              })
            "
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <customer-info />

    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div class="text-right">
          <v-btn color="primary" dark v-bind="attrs" v-on="on"> Add PDF </v-btn>
        </div>
      </template>

      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click.native="dialog = false">
              <v-img src="../../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>Add PDF to Bid</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="pr-10 pl-10">
                      <v-col cols="12" sm="12">
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <input
                              type="file"
                              ref="pdf"
                              accept="application/pdf"
                            />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-select
                              label="Type"
                              v-model="addPDF.type"
                              :rules="[(v) => !!v || 'This field is required']"
                              :items="select_from_open_slots.items"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-textarea
                              label="Comments"
                              v-model="addPDF.comments"
                              :rules="[(v) => !!v || 'This field is required']"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="text-right">
                              <v-btn color="primary" dark @click="saveFormData">
                                Add
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <datatable
      :headers="select_from_open_slots.headers"
      :staticData="select_from_open_slots.data"
      :componentURL="componentURL"
      :search="false"
    ></datatable>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";
import CustomerInfo from "./CustomerInfo";

export default {
  name: "AddPDF.vue",
  components: { CustomerInfo, Datatable },
  data() {
    return {
      valid: false,
      dialog: false,
      componentURL: "",
      select_from_open_slots: {
        headers: [
          { text: "File", value: "file" },
          { text: "Comments", value: "comments" },
          { text: "Type", value: "type" },
        ],
        data: [],
        items: ["pdf1", "pdf2", "pdf3"],
      },
      addPDF: {
        type: "",
        comments: "",
      },
    };
  },
  methods: {
    saveFormData() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.select_from_open_slots.data.push(this.addPDF);
        this.dialog = false;
        this.addPDF = {
          type: "",
          comments: "",
        };
      }
    },
  },
};
</script>

<style scoped></style>
